export const firebaseConfig = {
    "projectId": "guidde-production",
    "appId": "1:484452355157:web:2c5a764b5d360dbef6d8dc",
    "databaseURL": "https://guidde-production-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-production.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyATarjLOqtsa2O2VJqtnv81Dje5REI2TYc",
    "authDomain": "app.guidde.com",
    "messagingSenderId": "484452355157",
    "environment": "production"
}

export const SAOLA_ACCOUNT_TOKEN =
'856f10ef-4168-4be6-8d73-06030e21a9a0'
export const STIGG_CLIENT_KEY =
'$2b$10$vz2LfYEcVSchWKD8ZNlm1.zYHWPEdvjoWEHbKD3S9gWmT0yS80hou:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const entailApiKey =
'et-WJgibNJi14XN2kj8esMTq2ke_JhVpI8n5kq0lPv1-2eEnbIv'
export const isSeparateNetworkIssuesInMonitoring =
'true'
export const tapfiliateAccountId =
'38971-9bf427'